import { render, staticRenderFns } from "./shoptime_opt.vue?vue&type=template&id=8e9de554&scoped=true&"
import script from "./shoptime_opt.vue?vue&type=script&lang=js&"
export * from "./shoptime_opt.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e9de554",
  null
  
)

export default component.exports